body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Tajawal', sans-serif;
}

code {
  font-family: 'Tajawal', sans-serif;
}

section{
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Tajawal', sans-serif;
  color: #212121;
}

p{
  line-height: 30px;
}

span{
  display: block;
}

img{
  max-width: 100%;
  height: 100%;
}

a{
  text-decoration: none;
}

ul{
  margin: 0;
  padding: 0;
}

li{
  list-style: none;
}
