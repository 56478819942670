.pricing{
    margin: 40px 0;
}

.pricing .box{
    border : solid 1px #777777;
    border-radius: 5px ;
    background-color: #777777;
}

.pricing .box .btn{

}
