.footer-address{
    font-size: 3rem;
    text-align: center;
    color: #fb3e3e /*#7e5796*/;
}

.footer-text{
    text-align: center;
}

.footer-text a{
    text-decoration: none;
    color : #212121;
}
