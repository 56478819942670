header{
    width:100%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    background: url(../../../assets/img/bg-home.jpg) top no-repeat;
    background-size: cover;
}
.header-overlay{
    background-color: rgb(110, 58, 152, .7);
    width: 100%;
    max-width: 100%;
    height: auto;
    text-align: center;
    padding: 35px 0px;
}

header .header-overlay .row {
    margin: 60px 0px;
}

header h2{
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin: 35px 0px;
    letter-spacing: 1px;
    line-height: 75px;
}

header p{
    color: #fff;
    font-size: .9rem;
    line-height: 30px;
    margin: 25px 0px ;
}

header form{
    margin: 25px auto;
    background-color: #fff;
    width: 56%;
    max-width: 100%;
    padding: 7px;
    border-radius: 30px;
}

header form input{
    background-color: #fff;
    border: 1px solid #fff;
    outline: none !important;
    width: 450px;
    height: 45px;
    border-radius: 30px;
    text-align: left;
    color: #1f1f1f;
    font-size: 1rem;
    padding: 7px;

}

header form button{
    background-color: #fb3e3e;
    border: 1px solid #fb3e3e;
    width: 140px;
    height: 45px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
@media (max-width: 996px) {

    header form input{
        width: 320px ;
    }
    header form button{
        width: 110px ;
    }
}

@media (max-width: 766px) {

    header form input{
        width: 140px ;
    }
    header form button{
        width: 90px ;
        font-size: 12px;
    }
}

@media (max-width: 420px) {

    header form input{
        width: 140px ;
    }
}




